<template>
    <div class="home home_bgimg flex-column-between">
        <div class="home_her">
            <header class="flex-align-between head">
                <div class="avatar flex-align">
                    <div class="avatarImgb">
                        <img class="avatarImg" :src="avatar1" v-if="succeed" @click="goUser" />
                        <img class="avatarImg" src="../assets/user/user.png" v-else @click="goUser" />
                    </div>
                    <div class="avatarname flex-column-between">
                        <p id="tn">{{ UserInfo_data.name }}</p>
                        <p>{{ UserInfo_data.id == 459894 ? '东交民巷小学马坊分校' : schoolName }}</p>
                        <!-- <p>上课时长：{{ time(teacherTime) }}</p> -->
                        <p>上课次数：{{ teachercount }} 节</p>
                    </div>
                </div>
                <!-- <div class="schoolName flex-align-around">
                    <span class="school_dot"></span>
                    <span>
                        {{ UserInfo_data.id == 459894 ? '东交民巷小学马坊分校' : schoolName }}
                    </span>
                    <span class="school_dot"></span>
                </div> -->
                <div class="more">
                    <img src="@/assets/homepage/sync.png" class="item" @click="sync" />
                    <img src="@/assets/homepage/assist2.png" class="item" @click="question" />
                    <img src="@/assets/homepage/exit.png" class="item" @click="exit" />
                </div>
            </header>
            <news />
            <div class="entrance flex-align-center">
                <ul class="flex-align-between">
                    <li v-for="(item, i) in newNavs.filter(item => { return item.show })" :key="i">
                        <div class="vertical" @click="access(item)" :style="{ backgroundImage: 'url(' + item.imgs + ')' }">
                        </div>
                    </li>
                    <div class="entrance_right">
                        <li v-for="(item, i) in newNavs2" :key="i + 'a'">
                            <div class="across">
                                <div @click="access(item)" :style="{ backgroundImage: 'url(' + item.imgs + ')' }">
                                    <img :src="item.textImgs" alt="">
                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>
            <div class="entrance2 flex-align-center">
                <ul class="flex-align-between">
                    <li v-for="(item, i) in newCategory.filter(item => { return item.show })" :key="i">
                        <div :class="categoryType && categoryIndex == i ? 'categoryStyle' : ''"
                            @mouseenter="categoryenter(i)" @mouseleave="categoryleave(i)" @click="classify(item)">
                            <img :src="categoryType && categoryIndex == i ? item.imgs2 : item.imgs1" />
                        </div>
                        <p class="flex-align-around">{{ item.name }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="home_btm">
            <div class="taibg"></div>
            <!-- 上课弹出框 -->
            <div class="dialogbox">
                <el-dialog :visible.sync="centerDialogVisible" top="90px" class="attenddialog" title="选择课程" center
                    width="60%">
                    <div class="containe">
                        <div class="classgroup flex-layout">
                            <div class="Title">选择班级：</div>
                            <div class="classList" :class="unfoldShow ? 'classTall2' : 'classTall1'">
                                <ul :class="unfoldShow ? 'ul1' : 'ul2'">
                                    <!-- <li>{{defaultUnfold}}</li> -->
                                    <li :class="classIndex == index ? 'li1' : 'li2'" v-for="(item, index) in classArr"
                                        :key="index" @click="classBtn(index, item.termId, item.grade, item.id)">
                                        {{ item.name }}
                                    </li>
                                </ul>
                            </div>
                            <div class="more flex-align-between" @click="unfold" v-if="classArr.length > 4 ? true : false">
                                <span>更多</span>
                                <i class="el-icon-caret-bottom"></i>
                            </div>
                        </div>
                        <div class="unitgroup flex-layout" v-if="unitShow == 1">
                            <div class="Title Title2">选择单元：</div>
                            <!-- 删除空白单元节点 -->
                            <div class="unitList flex-layout">
                                <div class="unitbox">
                                    <ul>
                                        <li class="flex-align" v-for="(item, index) in unitArr" :key="index">
                                            <div @click="unitclick(index, item)" :class="{ unitbg: index == iconShow }"
                                                class="flex-align sonitem">
                                                <span>{{ item.name }}</span>
                                                <i class="el-icon-circle-check" :class="{ sonIcon: index != iconShow }"
                                                    v-if="item.unitResult"></i>
                                            </div>
                                            <i class="el-icon-caret-right" v-show="index == iconShow"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="taskBox">
                                    <div class="classPattern" v-if="classroom.length != 0">
                                        <ul class="">
                                            <li class="flex-align" v-for="(item, index) in classroom" :key="index">
                                                <div class="wordText flex-layout">
                                                    <div v-for="(itemSon, indexs) in item" :key="indexs"
                                                        @click="UnitLearning(itemSon.wordName, indexs, itemSon.wordId, itemSon.wordName)">
                                                        <div class="wordTextSon"
                                                            :class="[UnitLearnIndex == indexs && itemSon.wordName == UnitLearnName ? 'wordTextSon2' : itemSon.lastresult ? 'wordTextSon1' : '', itemSon.wordId - 0 < 0 ? 'special' : '',]">
                                                            <!-- itemSon.wordName.length > 1 ? 'special' : '', -->
                                                            {{ itemSon.wordName }}
                                                            <!-- {{  itemSon.wordName.length }} -->
                                                            <i class="el-icon-circle-check"
                                                                v-if="itemSon.result == 'true'"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="wordagebox4 flex-column-center" v-else>
                                        <img src="../assets/empty.png" />
                                        <span>期待同学们上传作品~</span>
                                        <span>{{ classroom.length }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="unitgroup flex-layout" v-if="unitShow == 2">
                            <div class="Title Title3">选择周：</div>
                            <div class="wordageList flex-layout">
                                <div class="wordagebox">
                                    <ul class="flex-column-center">
                                        <li class="flex-align" v-for="(item, index) in weeklist" :key="index">
                                            <div class="outerbox flex-align-between">
                                                <div class="flex-align outer" @click="weekClick(item.weekGroup, index)"
                                                    :class="{ outer2: index == weekIndex }">
                                                    <span>{{ item.weekGroupName }}</span>
                                                    <i class="el-icon-circle-check" :class="{ unitbg2: index != iconShow }"
                                                        v-show="item.weekGroupResult"></i>
                                                </div>
                                                <i class="el-icon-caret-right"
                                                    :class="{ rightIcon: index == weekIndex }"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="arrangeBox flex-layout">
                                    <div class="wordagebox wordagebox2" v-if="weeklist1.length != 0">
                                        <ul class="flex-column">
                                            <li class="flex-align" v-for="(item, index) in weeklist1" :key="index">
                                                <div class="interior">
                                                    <span :class="{ interiorColor: item.weekResult }">{{ item.weekName
                                                    }}</span>
                                                    <i class="el-icon-circle-check"
                                                        :class="{ interiorColor: item.weekResult }"
                                                        v-if="item.weekResult"></i>
                                                </div>
                                                <div class="wordText flex-layout">
                                                    <div v-for="(itemSon, indexs) in item.wordList" :key="indexs"
                                                        @click="individual(item.weekName, indexs, itemSon.wordId, itemSon.wordName)">
                                                        <div class="wordTextSon"
                                                            :class="wordTextIndex == indexs && item.weekName == wordTextName ? 'wordTextSon2' : itemSon.lastresult ? 'wordTextSon1' : ''">
                                                            {{ itemSon.wordName }}
                                                            <i class="el-icon-circle-check"
                                                                v-if="itemSon.result == 'true'"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="wordagebox wordagebox3 flex-column-center" v-else>
                                        <img src="../assets/empty.png" />
                                        <span>期待同学们上传作品~</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="unitgroup flex-layout" v-if="unitShow==3">
                            <div class="Title Title3">选课节：</div>
                            <div class="wordageList flex-layout">
                                <div class="wordagebox">
                                    <ul class="flex-column-center">
                                        <li class="flex-align" v-for="(item, index) in yingbiList" :key="index">
                                            <div class="outerbox flex-align-between">
                                                <div class="flex-align outer" @click="weekClick2(index)"
                                                    :class="{ outer2: index == weekIndex }">
                                                    <span>{{ item.name }}</span>
                                                    <i class="el-icon-circle-check"
                                                        :class="{ unitbg2: index != iconShow }"
                                                        v-show="item.weekGroupResult"></i>
                                                </div>
                                                <i class="el-icon-caret-right"
                                                    :class="{ rightIcon: index == weekIndex }"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="arrangeBox2 flex-align-center">
                                    <div class="wordagebox4 wordagebox2" v-if="yingbiLesson.length != 0">
                                        <ul class="flex-column">
                                            <li class="flex-align" v-for="(item, index) in yingbiLesson" :key="index">
                                                <div class="wordText flex-layout">
                                                    <div @click="individual2(index,item.lesson)">
                                                        <div
                                                            :class=" wordTextIndex == index? 'wordTextSon3': 'yingbiItem'">
                                                            {{ item.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="wordagebox wordagebox3 flex-column-center" v-else>
                                        <img src="../assets/empty.png" />
                                        <span>暂无课程</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="selection flex-layout">
                            <div class="pattern" :class="unitShow == 1 ? 'pattern2' : 'pattern1'" @click="unitShow = 1">
                                课堂模式
                            </div>
                            <div class="pattern" :class="unitShow == 2 ? 'pattern2' : 'pattern1'" @click="unitShow = 2">
                                每日一字
                            </div>
                            <div class="pattern" :class="unitShow == 3 ? 'pattern2' : 'pattern1'" @click="unitShow = 3">
                                硬笔体系课
                            </div>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer flex-column-center">
                        <el-button type="primary" @click="goclassRoom()">确定</el-button>
                        <div class="myCreate" @click="goHelper">我创建的</div>
                    </span>
                </el-dialog>
            </div>
            <!-- 上课弹出框 -->
            <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
            <div class="hint">
                <el-dialog title="提示" :visible.sync="centerDialogVisible3" width="30%" center>
                    <div class="tip flex-align-center">
                        {{ textValue }}
                    </div>
                    <div class="spanstyle flex-align-center">
                        <span @click="centerDialogVisible3 = false"> 关闭 </span>
                    </div>
                </el-dialog>
            </div>
        </div>
        <!-- 活动 -->
        <img class="colse" v-if="showActive" @click="colseActive" src="../assets/active/close.png" alt="">
        <div class="activityBox" @click="goActivity" v-if="showActive">
            <div class="activeName">
                <div>{{ activityParentName }}</div>
            </div>
            <img src="../assets/active/active.png" alt="">
        </div>
        <!-- 特色活动弹窗 -->
        <template v-if="activityList.length > 0">
            <el-dialog :visible="activityDialogVisible" custom-class="activitydialog" :show-close="false"
                :close-on-click-modal="false" :close-on-press-escape="false">
                <div class="activity_wrap" v-if="showType == 'list'">
                    <img class="colse" @click="closeActivityDialog('item')" src="../assets/active/close.png" alt="">
                    <img class="bg" src="../assets/active/activity_bg.png" alt="">
                    <div class="content flex-column-center">
                        <div class="title">{{ activityTitle }}</div>
                        <div class="classTitle">·优秀作品·</div>
                        <!-- 优秀作品轮播图 -->
                        <div class="banner flex-align">
                            <i class="el-icon-arrow-left" @click="prev"></i>
                            <el-carousel height="220px" arrow="never" indicator-position="none" ref="carousel">
                                <el-carousel-item v-for="(item, index) in activityList" :key="index">
                                    <div class="imgBox" v-for="(items, index) in item" :key="index"
                                        @click="showWork(items.id)">
                                        <img :src="items.file" alt="">
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                            <i class="el-icon-arrow-right" @click="next"></i>
                        </div>
                        <span class="more" @click="goActivity">查看更多></span>
                    </div>
                </div>
                <div class="detail_wrap flex-align" v-if="showType == 'item'"
                    :class="{ 'noResult': !currentWorkInfo.hasResult }">
                    <img class="colse" @click="closeActivityDialog('detail')" src="../assets/active/close.png" alt="">
                    <!-- <img src="../assets/active/detail_bg.png" alt="" class="detail_bg"> -->
                    <img class="work" :src="currentWorkInfo.imgUrl" alt="">
                    <div class="right" :class="{ 'noRight': !currentWorkInfo.hasResult }">
                        <div class="title flex-align">
                            <img src="../assets/active/cup.png" alt="">
                            获奖作品
                        </div>
                        <div class="line"></div>
                        <div class="item flex-align">
                            <span>作者姓名：</span>
                            <span>{{ currentWorkInfo.name }}</span>
                        </div>
                        <div class="item flex-align">
                            <span>作品ID：</span>
                            <span>{{ currentWorkInfo.id }}</span>
                        </div>
                        <div class="item2">
                            <span>综合评价：</span>
                        </div>
                        <div class="star flex-align-around">
                            <div class="star_wrap">
                                <div class="star_item flex-align" v-for="(item, index) in currentWorkInfo.commentType"
                                    :key="index">
                                    <span class="item_name flex-align"><span class="point"></span>{{ item.name }}</span>
                                    <el-rate disabled-void-color='#CCCCCC' allow-half disabled v-model="item.count">
                                    </el-rate>
                                </div>
                            </div>
                        </div>
                        <div class="item2">
                            <span>作品点评：</span>
                        </div>
                        <div class="comment">{{ currentWorkInfo.comment }}</div>
                    </div>
                </div>
            </el-dialog>
        </template>
        <div class="judgeRole">
            <el-dialog title="提示" :visible.sync="judgeRoleVisible" width="30%" center>
                <div class="tip flex-column-around">
                    <div class="flex-align-center" :class="goscreenIndex == 1 ? 'pseudoClass' : ''" @click="goscreen(1)">
                        本校数据驾驶舱</div>
                    <div class="flex-align-center" :class="goscreenIndex == 2 ? 'pseudoClass' : ''" @click="goscreen(2)">
                        {{ areaMaxNumber == 5 ? '全区' : '全国' }}数据驾驶舱</div>
                </div>
                <div class="spanstyle flex-align-center">
                    <span @click="multiRole"> 确认 </span>
                </div>
            </el-dialog>
        </div>
        <!-- 我教的课弹窗(单元模式、每日一字、硬笔体系课) -->
        <integratedCurriculum ref="integratedCurriculum" :schoolId="schoolId" :UserInfoData="UserInfo_data"
            :CourseType_home="functions" @popupVisible="popupVisible"></integratedCurriculum>
        <!-- 软笔体系课弹窗 -->
        <calligraphyPop ref="calligraphyPop" :schoolId="schoolId" :UserInfoData="UserInfo_data"
            @popupVisible="popupVisible"></calligraphyPop>
        <div class="beian flex-align">
            <img src="@/assets/beian.png" alt="">
            <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2021018572"
                style="color:#fff;margin-right:10px">京公网安备2021018572号</a>
            <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
                style="color:#fff;margin-right:10px">京ICP备：2021018572号-1</a>
        </div>
    </div>
</template>
<script>
import pdf from "vue-pdf";
import news from "./news/news";
import schoolCourseDataBase from "./schoolCourse.json"
import {
    mapState,
    mapMutations
} from "vuex";
import subscribe from "../Common_components/Popup/subscribe.vue";
import Axios from "axios";
import { slider, slideritem } from 'vue-concise-slider'
import calligraphyPop from "../Common_components/homeSubassembly/calligraphyPop.vue"
import integratedCurriculum from "../Common_components/homeSubassembly/integratedCurriculum.vue"
export default {
    components: {
        subscribe,
        pdf,
        news,
        slider,
        slideritem,
        calligraphyPop,
        integratedCurriculum
    },
    name: "Home",
    data() {
        return {
            // 教师信息
            UserInfo_data: "",
            schoolName: "",
            schoolId: "",
            avatar1: "",
            //班级信息
            classArr: [],
            class_id: "",
            classIndex: 0,
            defaultUnfold: "",
            gradeId: "",
            termId: "",
            noonClassId: "",
            classId: "",
            //课堂模式--单元
            Company_data: "",
            goclassType: 1,
            //午写模式--周--每周文字
            weeklist: [],
            weeklist1: [{
                classLearnedWordsList: [],
                weekGroup: "",
            },],
            wordTextIndex: "",
            wordTextName: "",
            wordageId: "",
            //征订判断
            school_condition: false,
            class_condition: false,
            // 上课弹窗
            centerDialogVisible: false,
            centerDialogVisible2: false,
            unitShow: 1,
            isShow: true,
            numPages: null,
            avatar: "null",
            avatarShow: true,
            defaultImg: require("@/assets/homepage/tou.png"),
            backgroundImage: "url(" + require("../assets/homepage/bg.png") + ")",
            navs: [{
                name: "上课",
                href: "/attend",
                imgs: require("../assets/homepage/lessonpbg.png"),
                show: false
            },
            {
                name: "活动",
                href: "/activity",
                imgs: require("../assets/homepage/attendclassbg.png"),
                show: false
            },
            {
                1: {
                    name: "AI测评",
                    href: "",
                    imgs: require("../assets/homepage/evaluation.png"),
                },
                2: {
                    name: "数据驾驶舱",
                    href: "",
                    imgs: require("../assets/homepage/dataScreen.png"),
                },
            },
            ],
            navs2: [{
                name: "活动",
                href: "/activity",
                imgs: require("../assets/homepage/attendclassbg.png"),
            },
            {
                1: {
                    name: "AI测评",
                    href: "",
                    imgs: require("../assets/homepage/evaluation.png"),
                },
                2: {
                    name: "数据驾驶舱",
                    href: "",
                    imgs: require("../assets/homepage/dataScreen.png"),
                },
            },
            ],
            centerDialogVisible3: false,
            ruleForm: "",
            class_isActive: 0,
            Company_isActive: 0,
            curriculum_isActive: 0,
            pdfUrl: "",
            //新功能
            iconShow: 0,
            taskText: "生字学习",
            unfoldShow: false,
            unitArr: [],
            task1: "",
            taskResult1: false,
            task2: "",
            taskResult2: false,
            taskImgarr1: [],
            taskImgarr2: [],
            taskId1: "",
            taskId2: "",
            // unitSonIndex: [],
            unitSingle1: [],
            unitSingle2: [],
            aloneId: "",
            textValue: "",
            // 新版
            weeklistName: {},
            weekIndex: 0,
            weektaskId: "",
            weekpop: "",
            // 页面缓存
            isFirstEnter: false,
            url: "../../public/4.pdf",
            pageTotalNum: 1,
            loadedRatio: 0,
            pageShow: false,
            //接收消息
            websocket: null,
            //全屏
            fullscreen: false,
            classroom: [],
            UnitLearnIndex: null,
            UnitLearnName: null,
            UnitLearnId: null,
            pattern: null,
            unitId: "",
            UserID: "",
            category: [{
                name: "我的字帖",
                href: "/sampleReels",
                imgs1: require("../assets/homepage/classExample1.png"),
                imgs2: require("../assets/homepage/classExample2.png"),
            },
            {
                name: "我的报告",
                href: "/sampleReels",
                imgs1: require("../assets/homepage/report1.png"),
                imgs2: require("../assets/homepage/report2.png"),
            },
            {
                name: "优秀作品",
                href: "/surfaceHome",
                imgs1: require("../assets/homepage/showreel1.png"),
                imgs2: require("../assets/homepage/showreel2.png"),
            },
            {
                name: "特色活动",
                href: "/newactivity_list",
                imgs1: require("../assets/active/activity.png"),
                imgs2: require("../assets/active/activity2.png"),
            }
            ],
            categoryType: false,
            categoryIndex: null,
            teacherTime: 0,
            teachercount: 0,
            succeed: false,
            judgeStatus: false,
            jurisdictionPhone: "",
            currentWordName: '',
            showActive: false,
            activityDialogVisible: false,
            showType: 'list',
            starValue: 0,
            activityList: [],
            currentWorkInfo: {
                name: '',
                id: '',
                imgUrl: '',
                commentType: [{
                    name: '作品完整度',
                    count: 1
                },
                {
                    name: '例字相似性',
                    count: 2
                }, {
                    name: '占格一致性',
                    count: 3
                }, {
                    name: '内容整齐度',
                    count: 4.5
                },
                ],
                comment: '',
                hasResult: true
            },
            haveActivity: false,
            activityTitle: '',
            activityParentId: null,
            activityParentName: '',
            activityDialogName: '',
            functions: [],
            newCategory: [
                {
                    name: "我的备课",
                    href: "/",
                    imgs1: require("../assets/newHome/Lessonpreparation.png"),
                    imgs2: require("../assets/newHome/Lessonpreparation_active.png"),
                    show: false
                },
                {
                    name: "我的字帖",
                    href: "/",
                    imgs1: require("../assets/newHome/copybook.png"),
                    imgs2: require("../assets/newHome/copybook_active.png"),
                    show: false
                },
                {
                    name: "我的直播",
                    href: "/",
                    imgs1: require("../assets/newHome/live.png"),
                    imgs2: require("../assets/newHome/live_active.png"),
                    show: false
                },
                {
                    name: "我的报告",
                    href: "/",
                    imgs1: require("../assets/newHome/report.png"),
                    imgs2: require("../assets/newHome/report_active.png"),
                    show: false
                },
                {
                    name: "优秀作品",
                    href: "/",
                    imgs1: require("../assets/newHome/works.png"),
                    imgs2: require("../assets/newHome/works_active.png"),
                    show: false
                },
                {
                    name: "特色活动",
                    href: "/",
                    imgs1: require("../assets/newHome/activity.png"),
                    imgs2: require("../assets/newHome/activity_active.png"),
                    show: false
                },
                {
                    name: "作品集",
                    href: "/",
                    imgs1: require("../assets/newHome/works2.png"),
                    imgs2: require("../assets/newHome/works2_active.png"),
                    show: false
                },
                {
                    name: "视频投影",
                    href: "/",
                    imgs1: require("../assets/newHome/video.png"),
                    imgs2: require("../assets/newHome/video_active.png"),
                    show: false
                },
                {
                    name: "软笔体系课",
                    href: "/",
                    imgs1: require("../assets/newHome/book.png"),
                    imgs2: require("../assets/newHome/book_active.png"),
                    show: false
                },
            ],
            newNavs: [{
                name: "上课",
                href: "/attend",
                imgs: require("../assets/homepage/lessonpbg.png"),
                show: false
            },
            {
                name: "活动",
                href: "/activity",
                imgs: require("../assets/homepage/attendclassbg.png"),
                show: false
            }
            ],
            newNavs2: [{
                name: "AI测评",
                href: "",
                imgs: require("../assets/homepage/evaluation.png"),
                textImgs: require("../assets/homepage/evaluationz.png"),
                show: true
            },
            {
                name: "数据驾驶舱",
                href: "",
                imgs: require("../assets/homepage/dataScreen.png"),
                textImgs: require("../assets/homepage/dataScreenz.png"),
                show: true
            },
            ],
            yingbiLink: '',
            // yingbiLesson: [],
            defaultClass: null,
            style: {
                'background': '#7caabe',
                'width': '23.5%',
                'margin-right': '2%'
            },
            judgeRoleVisible: false,
            goscreenIndex: 1,
            multiRoleData: '',
            areaMaxNumber: '',
        };
    },
    computed: {},
    created() {
        document.addEventListener("click", (e) => {
            if (this.$refs.showPanel) {
                let isSelf = this.$refs.showPanel.contains(e.target);
                if (!isSelf) {
                    this.isShow = false;
                }
            }
        });
    },
    mounted() {

        this.$nextTick(() => {
            this.Personnel_information();
        });
        this.getTeacherUploadInfo();
        this.judgeUser();
    },
    methods: {
        ...mapMutations(["attend_data", "hint_data", "message_data"]),
        //模块入口（上课--活动--作品集）
        async access(e) {
            // console.log(e)
            let _this = this;
            if (e.name == "活动") {
                // let resData = await _this.$Api.Home.getcheckTencentUser();
                // if (resData.data) {
                let data = await this.$Api.Home.checkSchoolRedirectUrl();
                if (data.data.length > 0) {
                    window.open(data.data[0].redirectUrl)
                } else {
                    let resData = await _this.$Api.Myactivity.getActivityList();
                    if (resData.data.length == 0) {
                        _this.hint_data("暂无活动");
                        _this.centerDialogVisible2 = true;
                    } else {
                        _this.$router.push({
                            name: "activity",
                        });
                    }
                }
                // } else {
                //     let resData = await _this.$Api.Myactivity.getActivityList();
                //     if (resData.data.length == 0) {
                //         _this.hint_data("暂无活动");
                //         _this.centerDialogVisible2 = true;
                //     } else {
                //         _this.$router.push({
                //             name: "activity",
                //         });
                //     }
                // }
            } else if (e.name == "上课") {
                let courseMustParameter = ['11', '12', '13', '14', '15', '16'];
                let that = this
                let containsAny = courseMustParameter.some(function (element) {
                    return that.functions.includes(element);
                });
                if (containsAny) {
                    this.$refs.integratedCurriculum.openPendialog();
                } else {
                    this.hint_data("暂无开放");
                    this.centerDialogVisible2 = true;
                }


            } else if (e.name == "AI测评") {
                this.$router.push({
                    path: '/syncProjection'
                })
            } else if (e.name == "数据驾驶舱") {
                this.queryUserMaxOrg();
            }
        },
        // 跳转新版报告
        goNewReport() {
            window.open(`/newreport?schoolId=${this.schoolId}&schoolName=${this.schoolName}&schoolName=home`, "_blank");
        },
        //腾讯报告
        async toReDirectReport() {
            let routeData;
            if (this.UserInfo_data.id == 459894) {
                window.open(`/newreport?schoolId=181062&schoolName=东交民巷小学马坊分校'&fromType=home`, "_blank");
                // routeData = this.$router.resolve({ path: '/newreport', query: { schoolId: 181062, schoolName: '东交民巷小学马坊分校', fromType: 'home' } });
            } else {
                window.open(`/newreport?schoolId=${this.schoolId}&schoolName=${this.schoolName}&fromType=home`, "_blank");
                // routeData = this.$router.resolve({ path: '/newreport', query: { schoolId: this.schoolId, schoolName: this.schoolName, fromType: 'home' } });
            }
            // window.open(routeData.href, '_blank');
            // console.log('this.schoolId',this.schoolId);
            // window.open(`/newreport?schoolId=${this.schoolId}&schoolName=${this.schoolName}&formType=home`, "_blank");
            // window.open('/oldreport', "_blank");
            // role:2教师3校长5区长 
            // if (this.UserInfo_data.roles.includes(3)) {
            //     window.open(`/newreport?schoolId=${this.schoolId}`, "_blank");
            // } else {
            //     let resData = await this.$Api.Home.getcheckTencentUser();
            //     if (resData.data) {
            //         let data = {
            //             userId: this.UserID,
            //         };
            //         let value = await this.$Api.Home.toReDirectReport(data);
            //         window.open(value.data);
            //     } else {
            //         this.hint_data("暂未开放");
            //         this.centerDialogVisible2 = true;
            //     }
            // }

        },
        async judgeUser() {
            let resData = await this.$Api.Home.getcheckTencentUser();
            this.judgeStatus = resData.data;
        },
        //教师信息
        async Personnel_information() {
            let resData = await this.$Api.Home.getUserInfo();

            if (resData.data.functions) {
                this.functions = resData.data.functions.split(',');
                this.$set(this.newNavs, 0, {
                    ...this.newNavs[0],
                    show: this.functions.includes('0')
                });
                this.$set(this.newNavs, 1, {
                    ...this.newNavs[1],
                    show: this.functions.includes('1')
                });
                //底部菜单
                // 我的备课
                this.$set(this.newCategory, 0, {
                    ...this.newCategory[0],
                    show: this.functions.includes('10')
                });
                // 我的字帖
                this.$set(this.newCategory, 1, {
                    ...this.newCategory[1],
                    show: this.functions.includes('7')
                });
                //我的直播
                this.$set(this.newCategory, 2, {
                    ...this.newCategory[2],
                    show: this.functions.includes('6')
                });
                // 我的报告
                this.$set(this.newCategory, 3, {
                    ...this.newCategory[3],
                    show: this.functions.includes('4')
                });
                // 优秀作品
                this.$set(this.newCategory, 4, {
                    ...this.newCategory[4],
                    show: this.functions.includes('3')
                });
                // 特色活动
                this.$set(this.newCategory, 5, {
                    ...this.newCategory[5],
                    // show: this.functions.includes('9')
                    show: false,
                    //     //入口改动-当前写死

                });
                // 作品集
                this.$set(this.newCategory, 6, {
                    ...this.newCategory[6],
                    show: this.functions.includes('2')
                });
                // 视频投影
                this.$set(this.newCategory, 7, {
                    ...this.newCategory[7],
                    show: this.functions.includes('5')
                });
            }

            this.UserInfo_data = resData.data;
            this.schoolName = this.UserInfo_data.schoolName;
            this.schoolId = this.UserInfo_data.schoolId;
            let animals = [
                "15712843421",
                "17710605186",
                "17634008758",
                "15101000124",
                "13472114070",
                "15652636001"
            ];
            this.jurisdictionPhone = this.UserInfo_data.phone;
            if (animals.includes(this.jurisdictionPhone)) {
                this.category.push({
                    name: "项目管理",
                    href: "/surfaceHome",
                    imgs1: require("../assets/homepage/report1.png"),
                    imgs2: require("../assets/homepage/report2.png"),
                })
            }

            // if (this.schoolId == "180708") {
            //   this.category.push({
            //     name: "数据驾驶舱",
            //     href: "",
            //     imgs1: require("../assets/homepage/cockpit1.png"),
            //     imgs2: require("../assets/homepage/cockpit2.png"),
            //   });
            // }
            this.avatar1 =
                "https://yunapi.cnuzi.com/uploads/" + this.UserInfo_data.avatar;
            this.errorimg(this.avatar1);
            this.avatar = this.UserInfo_data.avatar;
            sessionStorage.setItem(
                "avatar",
                JSON.stringify(this.UserInfo_data.avatar)
            );
            sessionStorage.setItem("teacherName", resData.data.name);
            sessionStorage.setItem("teacherId", resData.data.id);
            sessionStorage.setItem("roles", JSON.stringify(resData.data.roles));
            sessionStorage.setItem("role", resData.data.role);
            sessionStorage.setItem("schoolId", resData.data.schoolId)
            sessionStorage.setItem("schoolName", resData.data.schoolName)
            sessionStorage.setItem("gradeId", resData.data.gradeId)
            this.UserID = resData.data.id;
            this.$Api.Home.getCourseInfo()
                .then((res) => {
                    // console.log('班级列表:', res)
                    //判断是否显示软笔体系课
                    let newdata = res.data.filter(item => {
                        return item.termId >= 5 || item.grade >= 5 && item.grade <= 12;
                    });
                    if (newdata.length == 0) {
                        this.newCategory.forEach(item => {
                            if (item.name == '软笔体系课') {
                                item.show = false;
                            }

                        });
                    } else {
                        this.$set(this.newCategory, 8, {
                            ...this.newCategory[8],
                            show: this.functions.includes('8')
                            // show: false
                        });
                    }
                    // console.log('newdata', newdata);
                    if (res.code == 500) { } else {
                        this.classArr = res.data;
                        this.class_id = this.classArr[0].id;
                        this.defaultClass = this.classArr[0].id;
                        this.weeklist = [];
                        this.weeklist1 = [{
                            classLearnedWordsList: [],
                            weekGroup: "",
                        },];
                        this.classBtn(
                            0,
                            res.data[0].termId,
                            res.data[0].grade,
                            res.data[0].id
                        );
                        if (this.classArr[0].unitInfo != undefined) {
                            this.Company_data = this.classArr[0].unitInfo;
                        }
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                });
            this.hasActivity()
        },
        // 征订判断（学校征订--班级征订）
        async school_subscrip() {
            let resData = await this.$Api.Home.school_subscription();
            this.school_condition = resData.data.result;
            if (this.school_condition) {
                this.centerDialogVisible = true;
            } else {
                this.hint_data("暂无权限 ，请联系管理员开通！");
                this.centerDialogVisible2 = true;
            }
        },
        //防止闪图
        errorimg(src) {
            let that = this;
            let img = new Image();
            img.src = src;
            img.onload = function () {
                that.succeed = true;
            };
            img.onerror = function () {
                that.succeed = false;
            };
        },
        //退出
        exit() {
            // this.message_data(true)
            this.$confirm("此操作将退出应用, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "success",
                center: true,
                customClass: "MessClass",
                confirmButtonClass: "warningClass",
            })
                .then(() => {
                    this.$router.push({
                        name: "logins",
                        params: {
                            type: 1,
                        },
                    });
                })
                .catch(() => { });
        },
        //帮助手册
        question() {
            this.$router.push({
                name: "Helpmanual",
            });
        },
        // 同步投影 1.9需求新增
        sync() {
            this.$router.push({
                path: '/syncProjection'
            })
        },
        clear(bool) {
            this.centerDialogVisible2 = bool;
        },
        message() {
            this.hint_data("敬请期待！");
            this.centerDialogVisible2 = true;
        },
        //新功能
        //点击班级
        async classBtn(index, termId, grade, id) {
            this.classIndex = index;
            this.defaultUnfold = this.classArr[index].name;
            // 班级id
            this.classId = id;
            this.noonClassId = id;
            this.gradeId = grade;
            this.termId = termId;
            //课堂
            this.goclassType = 1;
            this.unitArr = [];
            this.weeklist = [];
            this.classroom = [];
            this.getUnittable(termId, id);
            //午写
            this.weeklist1 = [];
            this.wordTextIndex = "";
            this.wordTextName = "";
            this.wordageId = "";
            this.getweekList(grade, id, termId);
            this.unfoldShow = false;
        },
        //课堂模式
        async getUnittable(termId, id) {
            this.unitArr = [];
            this.classroom = [];
            let data = {
                termId: termId,
                classId: id,
            };
            let resData = await this.$Api.Myclass.getUnittable(data);
            this.unitArr = resData.data;
            let Index = 0;
            if (resData.data.length == 0) return false;
            for (let i = 0; i < this.unitArr.length; i++) {
                if (this.unitArr[i].lastTaskUnitResult) {
                    Index = i;
                }
            }
            this.unitclick(Index, this.unitArr[Index]);
        },
        async unitclick(index, valve) {
            this.unitId = valve.unitId;
            this.classroom = [];
            this.iconShow = index;
            let data = {
                gradeId: valve.termId,
                classId: this.classId,
                unitId: valve.unitId,
            };
            let resData = await this.$Api.Myclass.getClassWordsVoList(data);
            if (resData.code == 500) return false;
            // console.log("resData.data[0].unitName", resData.data[0].unitName);
            // console.log("this.judgeStatus", this.judgeStatus);
            // console.log("resData.data[0].wordList", resData.data[0].wordList);
            if (resData.data[0].unitName == "第八单元" && this.judgeStatus) {
                resData.data[0].wordList.push({
                    lastresult: false,
                    result: "false",
                    wordId: -1,
                    wordName: "五色鹦鹉图",
                }, {
                    lastresult: false,
                    result: "false",
                    wordId: -2,
                    wordName: "生活中的吉语",
                }, {
                    lastresult: false,
                    result: "false",
                    wordId: -3,
                    wordName: "你不知道的青铜器-利簋",
                }, {
                    lastresult: false,
                    result: "false",
                    wordId: -4,
                    wordName: "动物王国开大会",
                }, {
                    lastresult: false,
                    result: "false",
                    wordId: -5,
                    wordName: "稻草人",
                });
            }
            let arr = resData.data[0].wordList;
            // console.log("arr", arr);
            let result = [];
            let len = arr.length;
            for (var i = 0; i < len; i += 5) {
                result.push(arr.slice(i, i + 5));
            }
            this.classroom = result;
            // console.log("this.classroom", this.classroom);
        },
        UnitLearning(name, index, wordId, wordName) {
            this.currentWordName = wordName;
            this.UnitLearnIndex = index;
            this.UnitLearnName = name;
            this.wordageId = wordId;
            this.pattern = 0;
        },
        // 午写模式
        weekClick(weekGroupId, index) {
            this.weekIndex = index;
            this.wordTextIndex = "";
            this.wordTextName = "";
            this.wordageId = "";
            this.weeklist1 = [];
            this.getweek(weekGroupId, this.classId, this.termId);
        },
        // 硬笔书法点击
        weekClick2(index) {
            // console.log('index:', index);
            this.weekIndex = index;
            // this.getPenLesson2(index + 1)
        },
        individual(name, index, wordId, wordName) {
            this.currentWordName = wordName;
            this.wordTextIndex = index;
            this.wordTextName = name;
            this.wordageId = wordId;
            this.pattern = 1;
            this.getweektask(this.wordageId);
        },
        individual2(index, lesson) {
            this.wordTextIndex = index;
            this.yingbiLink = lesson;
        },
        // 午写taskid
        async getweektask(wordId) {
            let data = {
                wordId: wordId,
            };
            let resData = await this.$Api.Myclass.getTaskIdByWordId(data);
            this.weektaskId = resData.data;
        },
        // 午写周请求
        async getweekList(gradeID, classId, termId) {
            this.weeklist = [];
            let data = {
                gradeId: termId,
                classId: classId,
            };
            let resData = await this.$Api.Myclass.getWeekIdsByGradeId(data);
            this.weeklist = resData.data;
            if (resData.data.length == 0) return false;
            for (let i = 0; i < this.weeklist.length; i++) {
                if (this.weeklist[i].lastLearnedWordInWeekGroup) {
                    this.weekIndex = i;
                }
            }

            this.getweek(resData.data[this.weekIndex].weekGroup, classId, termId);
        },
        async getweek(id, classId, termId) {
            let data = {
                gradeId: termId,
                classId: classId,
                weekGroupId: id,
            };
            let resData = await this.$Api.Myclass.getWordsVoList(data);
            this.weeklist1 = resData.data;
        },
        // 点击上课
        goclassRoom() {
            if (this.unitShow == 3) {
                window.open(this.yingbiLink)
                return
            }
            let _this = this;
            if (!this.school_condition) return false;
            if (this.wordageId == "") {
                this.centerDialogVisible3 = true;
                this.textValue = "请选择生字";
                return false;
            }
            let data = {
                classId: _this.classId,
                wordId: _this.wordageId,
            };
            if (this.jurisdictionPhone == '13520666080') {
                // this.hideClass = true
                // 判断是否是指定字，非指定字提示暂未开放
                let wordList = ['冬', '飞', '童', '丝', '燕', '溪'];
                if (wordList.includes(this.currentWordName)) {
                    _this.$Api.Home.checkTencentWord(data).then((res) => {
                        if (res.data) {
                            this.addClassLog()
                            _this.CreateLesson();
                            //腾讯跳转
                            _this.queryWordUrl(this.wordageId);
                            _this.centerDialogVisible = false;
                        } else {
                            this.centerDialogVisible3 = true;
                            this.textValue = "暂未开放!";
                        }
                    });
                } else {
                    this.centerDialogVisible3 = true;
                    this.textValue = "暂未开放!";
                    return false;
                }
            } else {
                _this.$Api.Home.checkTencentWord(data).then((res) => {
                    if (res.data) {
                        this.addClassLog()
                        _this.CreateLesson();
                        //腾讯跳转
                        _this.queryWordUrl(this.wordageId);
                        _this.centerDialogVisible = false;
                    } else {
                        let data = {
                            wordId: _this.wordageId,
                        };
                        _this.$Api.Myclass.getImgList(data)
                            .then((res) => {
                                if (res.code == 500 || res.data.enclosure == undefined) {
                                    this.centerDialogVisible3 = true;
                                    this.textValue = "暂未开放!";
                                    return false;
                                } else {
                                    this.addClassLog()
                                    _this.$router.push({
                                        path: "/pdfdemo",
                                        query: {
                                            taskId: _this.weektaskId,
                                            unitId: _this.unitId,
                                            wordId: _this.wordageId,
                                            classId: _this.classId,
                                            type: _this.pattern,
                                            gradeId: _this.gradeId,
                                            termId: _this.termId,
                                        },
                                    });
                                    _this.centerDialogVisible = false;
                                }
                            })
                            .catch((err) => {
                                console.log("err", err);
                            });
                    }
                });
            }
        },
        // 我创建的 跳转教师助手
        goHelper() {
            let data = {
                url: '#/course/created-quda'
            }
            // window.open("https://teacher.qq.com/#/course/created-quda");
            this.$Api.Home.getDirectUrl2(data)
                .then(res => {
                    // console.log(res);
                    window.open(res.data);
                })
                .catch(err => {
                    console.log(err)
                })
        },
        queryWordUrl() {
            let data = {
                wordId: this.wordageId,
            };
            this.$Api.Home.queryWordUrl(data).then((res) => {
                window.open(res.data);
            });
        },
        // 统计时间
        CreateLesson() {
            let value = {
                taskId: this.weektaskId,
                unitId: this.unitId,
                wordId: this.wordageId,
                classId: this.classId,
                type: this.pattern,
                gradeId: this.gradeId,
                termId: this.termId,
            };
            let data = {
                params: JSON.stringify(value),
                wordId: this.wordageId,
                classId: this.classId,
            };
            this.$Api.Home.CreateLesson(data).then((res) => { });
        },
        add() {
            this.hint_data("暂未开放");
            this.centerDialogVisible2 = true;
        },
        // 删除
        goassistant() {
            this.$router.push({
                name: "painter",
            });
        },
        unfold() {
            this.unfoldShow = !this.unfoldShow;
        },
        categoryenter(index) {
            this.categoryIndex = index;
            this.categoryType = true;
        },
        categoryleave(index) {
            this.categoryIndex = index;
            this.categoryType = false;
        },
        classify(item) {
            // console.log(item)
            let obj = {
                "我的备课": {
                    function: () => {
                        this.goHelper();
                    }
                },
                "我的字帖": {
                    function: () => {
                        this.$router.push('/copybook');
                    }
                },
                "我的直播": {
                    function: () => {
                        this.$router.push('/livePanel');
                    }
                },
                "我的报告": {
                    function: () => {
                        // window.open('/report', "_blank");
                        this.toReDirectReport();
                        // this.hint_data("暂未开放");
                        // this.centerDialogVisible2 = true;
                    }
                },
                "优秀作品": {
                    function: () => {
                        this.$router.push({
                            name: "surfaceHome",
                            query: {
                                type: "0",
                            },
                        });
                    }
                },
                "特色活动": {
                    function: () => {
                        this.$router.push('/newactivity_list');
                    }
                },
                "作品集": {
                    function: () => {
                        this.$router.push({
                            path: "/curriculum",
                        });
                    }
                },
                "视频投影": {
                    function: () => {
                        this.$router.push({
                            path: '/videoProjection'
                        })
                    }
                },
                "软笔体系课": {
                    function: () => {
                        this.$refs.calligraphyPop.openPendialog();
                    }
                }
            }
            obj[item.name].function()
        },
        //判断用户角色组织
        async queryUserMaxOrg() {
            let resData = await this.$Api.Home.queryUserMaxOrg();
            const roleArray = resData.data.roles.map(item => item.id);
            if (roleArray.length == 1) {
                this.singleRole(resData)
            } else {
                // 判断 roleArray 是否包含 1 2 3 4 6 7
                const filteredRoleIds = roleArray.filter(roleId => [2, 3].includes(roleId));
                console.log('filteredRoleIds', filteredRoleIds);
                // 判断 roleArray 是否包含 5区领导 或 8 平台管理员
                const regulate = roleArray.filter(roleId => [5, 8].includes(roleId));
                console.log('regulate', regulate);
                const regulatemaxNumber = Math.max.apply(null, regulate);
                const filteredRoleIdsmaxNumber = Math.max.apply(null, filteredRoleIds);
                console.log('regulatemaxNumber', regulatemaxNumber);
                console.log('filteredRoleIdsmaxNumber', filteredRoleIdsmaxNumber);
                if (filteredRoleIds.length != 0 && regulate.length != 0) {
                    this.judgeRoleVisible = true;
                    this.multiRoleData = resData;
                    console.log('multiRoleData', this.multiRoleData);
                    this.schoolMaxNumber = filteredRoleIdsmaxNumber;
                    console.log('this.schoolMaxNumber', this.schoolMaxNumber);
                    this.areaMaxNumber = regulatemaxNumber;
                    console.log('this.areaMaxNumber', this.areaMaxNumber);
                    // 多角色
                } else if (filteredRoleIds.length == 0 && regulate.length != 0) {
                    //单有区域角色
                    if (regulatemaxNumber == 5) {
                        let name = await this.getById(resData.data.bindId);
                        console.log('name', name);
                        //区领导
                        this.$router.push({
                            path: '/NewAreaData',
                            query: {
                                rank: 2,
                                id: resData.data.bindId,
                                name: name
                            }
                        });
                    } else if (regulatemaxNumber == 8) {
                        //平台管理员
                        this.$router.push({
                            path: '/nationwideData',
                            query: {
                            }
                        });
                    }
                } else if (filteredRoleIds.length != 0 && regulate.length == 0) {
                    //单有其它角色
                    if (filteredRoleIdsmaxNumber == 2) {
                        //教师
                        // let routeData = this.$router.resolve({
                        //     path: "/dataScreenMain/classData",
                        //     query: {
                        //         type: resData.data.type,
                        //         role: resData.data.role,
                        //         schoolName: this.schoolName
                        //     },
                        // });
                        // window.open(routeData.href, "_blank");
                        let routeData = this.$router.resolve({
                            path: "/dataScreenMain/classData",
                            query: {
                                type: resData.data.type,
                                role: resData.data.role,
                                schoolName: this.schoolName
                            },
                        });
                        window.open(routeData.href, "_blank");
                    } else if (filteredRoleIdsmaxNumber == 3) {
                        //校长
                        let routeData = this.$router.resolve({
                            path: "/dataScreenMain/schoolData",
                            query: {
                                type: resData.data.type,
                                role: resData.data.role,
                                userId: resData.data.userId,
                                orgId: resData.data.orgId,
                                bindId: resData.data.bindId,
                                name: resData.data.name,
                            },
                        });
                        window.open(routeData.href, "_blank");
                    }
                }


            }
        },
        //获取地区
        async getById(id) {
            let data = {
                id: id,
            };
            let resData = await this.$Api.DataScreen.getById(data);
            return resData.data.name;
        },
        async getTeacherUploadInfo() {
            this.$Api.Home.getTeacherUploadInfo().then((res) => {
                this.teacherTime = res.data.totalLength;
                this.teachercount = res.data.totalLength;
            });
        },
        time(time) {
            var h = parseInt(
                ((time * 1000) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            var m = parseInt(((time * 1000) % (1000 * 60 * 60)) / (1000 * 60));
            var s = parseInt(((time * 1000) % (1000 * 60)) / 1000);
            return h + "时" + m + "分" + s + "秒";
        },
        addtTx() {
            window.open("https://teacher.qq.com/#/course/created-quda");
        },
        goUser() {
            this.$router.push("/profile");
        },
        // 上课记录
        addClassLog() {
            // console.log('添加上课记录')
            let data = {
                classId: this.defaultClass,
                userId: this.UserInfo_data.id,
                roleId: this.UserInfo_data.role,
                title: this.currentWordName,
                content: '',
                type: 1,
                action: '互动课件',
                schoolId: this.schoolId
            }
            this.$Api.Home.addLog(data)
                .then(res => {
                    // console.log('添加上课记录成功:', res)
                })
                .catch(err => {
                    console.log('添加上课记录失败:', err)
                })
        },
        colseActive() {
            this.showActive = false
        },
        // 切换轮播图
        prev() {
            this.$refs.carousel.prev()
        },
        next() {
            this.$refs.carousel.next()
        },
        // 跳转特色活动
        goActivity() {
            // this.$router.push({
            //     path: "/newactivity",
            //     query: {
            //         activityParentId: this.jurisdictionPhone == '18911630227' || this.jurisdictionPhone ==
            //             '18034212321' ? 13 : this.activityParentId,
            //         activityParentName: this.jurisdictionPhone == '18911630227' || this.jurisdictionPhone ==
            //             '18034212321' ? '翰墨抒中华，深情颂党恩' : this.activityParentName
            //     }
            // });
            this.$router.push({
                path: "/newactivity_login",
                query: {
                    activityParentId: this.activityParentId,
                    activityParentName: this.activityParentName,
                    type: 'yanshi'
                }
            });
        },
        // 显示作品详情
        showWork(uploadId) {
            this.showType = 'item';
            let data = {
                uploadId
            };
            this.$Api.Activity.getWorkDetail(data)
                .then(res => {
                    // console.log('作品详情:', res);
                    if (res.data.scoreAvg < 0) {
                        this.currentWorkInfo.hasResult = false;
                        this.currentWorkInfo.imgUrl = res.data.file
                    } else {
                        this.currentWorkInfo.hasResult = true;
                        this.currentWorkInfo.name = res.data.studentName;
                        this.currentWorkInfo.id = res.data.wordId;
                        this.currentWorkInfo.comment = res.data.comment;
                        this.currentWorkInfo.imgUrl = res.data.file
                        let wzd = Math.floor((res.data.writeCount / res.data.maxNum) * 5);
                        let xsx = Math.floor(res.data.scoreAvg / 10);
                        let yzx = Math.floor(res.data.sizeOffset / 10);
                        let zqd = Math.floor(res.data.locationOffset / 10);
                        // console.log('wzd:', wzd);
                        // console.log('xsx:', xsx);
                        // console.log('yzx:', yzx);
                        // console.log('zqd:', zqd);
                        this.$set(this.currentWorkInfo.commentType, 0, {
                            ...this.currentWorkInfo.commentType[0],
                            count: wzd
                        });
                        this.$set(this.currentWorkInfo.commentType, 1, {
                            ...this.currentWorkInfo.commentType[1],
                            count: xsx * 0.5
                        });
                        this.$set(this.currentWorkInfo.commentType, 2, {
                            ...this.currentWorkInfo.commentType[2],
                            count: yzx * 0.5
                        });
                        this.$set(this.currentWorkInfo.commentType, 3, {
                            ...this.currentWorkInfo.commentType[3],
                            count: zqd * 0.5
                        });
                    }

                })
                .catch(err => {
                    console.log('作品详情失败:', err)
                })
        },
        // 检查当前是否有活动
        hasActivity() {
            this.$Api.Activity.checkHasActive()
                .then(res => {
                    // console.log('是否有特色活动:', res);
                    if (!res.data.haveActivity) {
                        this.showActive = false;
                    } else {
                        this.showActive = true;
                        this.activityTitle = res.data.currentName;
                        this.activityParentId = res.data.activityParentId;
                        this.activityParentName = this.jurisdictionPhone == '18911630227' || this
                            .jurisdictionPhone == '18034212321' ? '翰墨抒中华，深情颂党恩' : res.data.activityParentName;
                        this.activityDialogName = this.jurisdictionPhone == '18911630227' || this
                            .jurisdictionPhone == '18034212321' ? '翰墨抒中华，深情颂党恩' : res.data.currentName
                        // 获取活动数据
                        let data = {
                            activityId: res.data.currentId,
                            type: 2, //父活动1 子活动2
                            teacherId: this.UserInfo_data.role == 2 ? this.UserInfo_data.id : '',
                            page: 1,
                            limit: 999,
                        }
                        this.$Api.Activity.getWorks(data)
                            .then(res => {
                                // console.log('活动数据:', res);
                                const groupSize = 3;
                                for (let i = 0; i < res.data.list.length; i += groupSize) {
                                    let group = res.data.list.slice(i, i + groupSize);
                                    this.activityList.push(group);
                                }
                                // this.activityDialogVisible = true;
                                let isClose = sessionStorage.getItem('isClose');
                                // console.log('isClose:', isClose);
                                if (isClose) {
                                    this.activityDialogVisible = false
                                } else {
                                    this.activityDialogVisible = true
                                }
                                // console.log(this.activityList)
                            })
                            .catch(err => {
                                console.log('获取活动失败:', err)
                            })

                    }
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 关闭活动
        closeActivityDialog(type) {
            if (type == 'detail') {
                this.showType = 'list'
            } else {
                this.activityDialogVisible = false;
                sessionStorage.setItem('isClose', true)
            }
        },
        //选择课程（同步课程-每日一字-硬笔体系课-校本课程-软笔体系课）
        popupVisible() {
            this.centerDialogVisible2 = true;
        },
        goscreen(index) {
            this.goscreenIndex = index;
        },
        //多角色跳转
        async multiRole() {
            console.log('this.multiRoleData', this.multiRoleData);
            if (this.goscreenIndex == 1) {
                if (this.schoolMaxNumber == 2) {
                    let data = this.getItemData(this.multiRoleData.data, 2);
                    //教师
                    let routeData = this.$router.resolve({
                        path: "/dataScreenMain/classData",
                        query: {
                            type: data.type,
                            role: 2,
                            schoolName: this.schoolName
                        },
                    });
                    window.open(routeData.href, "_blank");
                } else {
                    //校长
                    let data = this.getItemData(this.multiRoleData.data, 3);
                    console.log('data', data);
                    let routeData = this.$router.resolve({
                        path: "/dataScreenMain/schoolData",
                        query: {
                            type: data.type,
                            role: 3,
                            userId: this.multiRoleData.data.userId,
                            orgId: data.orgId,
                            bindId: data.bindId,
                            name: data.orgName,
                        },
                    });
                    window.open(routeData.href, "_blank");
                }
            } else {
                if (this.areaMaxNumber == 5) {
                    console.log('this.multiRoleData', this.multiRoleData);
                    let data = this.getItemData(this.multiRoleData.data, 5);
                    console.log('data', data);
                    let name = await this.getById(data.bindId);
                    //区领导
                    this.$router.push({
                        path: '/NewAreaData',
                        query: {
                            rank: 2,
                            id: data.bindId,
                            name: data.orgName,
                        }
                    });
                } else {
                    this.$router.push({
                        path: '/nationwideData',
                        query: {
                        }
                    });
                }
            }
            this.judgeRoleVisible = false;
        },
        //单角色跳转
        async singleRole(resData) {
            console.log('单角色跳转', resData);
            if (resData.data.role == 2) {
                //教师
                let routeData = this.$router.resolve({
                    path: "/dataScreenMain/classData",
                    query: {
                        type: resData.data.type,
                        role: resData.data.role,
                        schoolName: this.schoolName
                    },
                });
                window.open(routeData.href, "_blank");
            } else if (resData.data.role == 3) {
                //校长
                console.log('校长resData.data', resData.data);
                let routeData = this.$router.resolve({
                    path: "/dataScreenMain/schoolData",
                    query: {
                        type: resData.data.type,
                        role: resData.data.role,
                        userId: resData.data.userId,
                        orgId: resData.data.orgId,
                        bindId: resData.data.bindId,
                        name: resData.data.name,
                    },
                });
                window.open(routeData.href, "_blank");
            }
            // else if (resData.data.role == 8) {
            //     //平台管理员
            //     this.$router.push({
            //         path: '/nationwideData',
            //         query: {
            //         }
            //     });
            // } else if (resData.data.role == 5) {
            //     let name = await this.getById(resData.data.bindId);
            //     //区领导
            //     this.$router.push({
            //         path: '/NewAreaData',
            //         query: {
            //             rank: 2,
            //             id: resData.data.bindId,
            //             name: name
            //         }
            //     });
            // }
        },

        //获取该项数据
        getItemData(data, id) {
            let arr;
            data.roles.forEach(element => {
                if (element.id == id) {
                    arr = element;
                }
            });
            return arr;
        },
        // 我创建的 跳转教师助手
        goHelper() {
            let data = {
                url: '#/course/created-quda'
            }
            // window.open("https://teacher.qq.com/#/course/created-quda");
            this.$Api.Home.getDirectUrl2(data)
                .then(res => {
                    window.open(res.data);
                })
                .catch(err => {
                    console.log(err)
                })
        },
    },
};
</script>

<style lang="less" scoped>
@import "less/Home.less";
</style>
<style>
.beian {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    font-size: 12px;
}

.pdf {
    width: 106%;
    margin-top: -69px;
    margin-left: -7px;
    margin-bottom: -12px;
    height: 650px;
}

/* element弹出框样式 */
.attenddialog .el-dialog {
    border-radius: 10px;
    min-height: 430px;
    padding: 0;
}

.attenddialog .el-dialog__body {
    padding: 0;
}

.attenddialog .demo-ruleForm {
    font-size: 19px;
    color: #000;
    min-height: 280px;
}

.attenddialog .el-form-item {
    margin-bottom: 26px;
    padding-right: 1px;
}

.demo-ruleForm .el-form-item__label {
    font-size: 19px;
    color: #000;
    padding: 0;
}

.attenddialog .el-button+.attenddialog .el-button {
    margin-left: 0px;
}

.attenddialog .el-button.is-plain:focus,
.attenddialog .el-button.is-plain:hover {
    background: #21bd99;
    color: #ffffff;
    border-color: #dcdfe6;
}

.attenddialog .dialog-footer .el-button {
    background: #21bd99;
    color: #ffffff;
    border-color: #dcdfe6;
    width: 187px;
    height: 52px;
}
</style>