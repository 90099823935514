<template>
    <div>
        <!-- 我教的课弹窗(单元模式、每日一字、硬笔体系课) -->
        <el-dialog :visible="myTeachCourseVisible" custom-class="myTeachdialog flex-column" :show-close="false"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="penTitle flex-align-center">选择课程</div>
            <div class="courseBox">
                <img src="../../assets/pen/close.png" class="close" @click="closePendialog">
                <div class="model flex-align-around">
                    <div class="modelItem flex-align-center" :class="{ 'modelItem_active': defaultModelIndex == index }"
                        v-for="(item, index) in modelList.filter(item => { return item.show })" :key="index"
                        @click="selectModel(index, item.type)">
                        {{ item.name }}
                    </div>
                </div>
                <div class="classBox_border">
                    <div class="classBox flex-layout">
                        <span class="selectClass flex-align-center">选择班级:</span>
                        <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-left" v-if="classList.length > 6"
                                @click="slidePreClass" @touchstart.prevent="slidePreClass"></i></div>
                        <div class="classList_slide flex-align-center">
                            <slider ref="slider1" :options="options" :currentPage="currentIndex" is-prevent="true">
                                <slideritem v-for="(item, index) in classList" :key="index">
                                    <div class="classList_slide_class flex-align-center"
                                        :class="{ 'course_item_active': defaultClass == item.id }"
                                        @click="selectClass(index, item.id, item.grade, item.teacherId, item.termId, item.schoolId)"
                                        @touchstart.prevent="selectClass(index, item.id, item.grade, item.teacherId, item.termId, item.schoolId)">
                                        {{ item.name }}</div>
                                </slideritem>
                            </slider>
                        </div>
                        <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-right" v-if="classList.length > 6"
                                @click="slideNextClass" @touchstart.prevent="slideNextClass"></i></div>
                    </div>
                    <!-- defaultModelIndex == 2 || defaultModelIndex == 1 -->
                    <div class="classBox flex-layout" v-if="course_type == 1 || course_type == 3">
                        <span class="selectClass flex-align-center">课程:</span>
                        <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-left"
                                v-if="course_classList.length > 6" @click="slidePreClassCourse"
                                @touchstart.prevent="slidePreClassCourse"></i></div>
                        <div class="classList_slide flex-align-center">
                            <slider ref="slider2" :draggable="false" :options="options">
                                <slideritem v-for="(item, index) in course_classList" :key="index">
                                    <div class="classList_slide_class flex-align-center"
                                        :class="{ 'course_item_active': defaultCourse == item.brushLevelId }"
                                        @click="selectCourse_slider(index, item.brushLevelId, item.level)"
                                        @touchstart.prevent="selectCourse_slider(index, item.brushLevelId, item.level)">{{
                                            item.brushName
                                        }}
                                    </div>
                                </slideritem>
                            </slider>
                        </div>
                        <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-right"
                                v-if="course_classList.length > 6" @click="slideNextCourse"
                                @touchstart.prevent="slideNextCourse"></i></div>
                    </div>
                    <!-- course_type == 4 -->
                    <div class="classBox flex-layout" v-if="false">
                        <span class="selectClass flex-align-center">课程:</span>
                        <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-left"
                                v-if="association_classLsit.length > 6" @click="slidePreClassCourse"
                                @touchstart.prevent="slidePreClassCourse"></i></div>
                        <!-- @click="slidePreClassCourse"
                                 -->
                        <div class="classList_slide flex-align-center">
                            <slider ref="slider2" :draggable="false" :options="options">
                                <slideritem v-for="(item, index) in association_classLsit" :key="index">
                                    <div class="classList_slide_class flex-align-center"
                                        :class="{ 'course_item_active': defaultassociation == item.id }"
                                        @click="association_slider(index, item.id)"
                                        @touchstart.prevent="association_slider(index, item.id)">
                                        {{
                                            item.courseName
                                        }}
                                    </div>
                                </slideritem>
                            </slider>
                        </div>
                        <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-right"
                                v-if="association_classLsit.length > 6" @click="slideNextCourse"
                                @touchstart.prevent="slideNextCourse"></i></div>
                    </div>
                    <!-- <div class="classBox_placeholder" v-else></div> -->
                </div>
                <div class="course_box flex-layout">
                    <span class="selectCourse">选课:</span>
                    <div class="content">
                        <template v-if="course_type == 4 || course_type == 5">
                            <div class="left_school flex-layout">
                                <div class="left_item flex-align-center"
                                    :class="{ 'left_item_active': associationIndex == index + 1 }"
                                    v-for="(item, index) in 16" :key="index" @click="selectCourse(index + 1, course_type,)">
                                    {{ item }}周
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="left flex-column-around"
                                :class="{ 'left_yingbi': course_type == 0 || course_type == 1 || course_type == 2 }">
                                <div class="left_item flex-align-center"
                                    :class="{ 'left_item_active': cellDefaultIndex == index }"
                                    v-for="(item, index) in course_type == 0 || course_type == 2 ? cellList : penList"
                                    :key="index" @click="selectCourse(index, course_type)">
                                    {{ course_type == 0 || course_type == 2 ? item.name.split('：')[0] : item }}
                                    {{ course_type == 1 ? '周' : course_type == 3 ? '课' : '' }}
                                </div>
                            </div>
                        </template>
                        <div class="right">
                            <!-- 单元测试 -- 简洁版 -->
                            <div class="type_cell" v-if="course_type == 0 || course_type == 2">
                                <template v-if="cellWordList.length > 0">
                                    <div class="word_box">
                                        <div class="word_item flex-align-center"
                                            :class="{ 'word_item_active': defaultCellWordId == item.id }"
                                            v-for="(item, index) in cellWordList" :key="index"
                                            @click="selectCourseItem(index, item.url, course_type, item.id, item.name)">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                </template>
                                <div class="wordagebox3 flex-column-center" v-else>
                                    <img src="../../assets/empty.png" />
                                    <span>暂无内容~</span>
                                </div>
                            </div>
                            <!-- 每日一字 -->
                            <div class="type_day flex-column-between" v-if="course_type == 1">
                                <div class="week_item flex-align" v-for="(item, index) in dayWordList" :key="index">
                                    <span>第{{ item.weekNumber }}周</span>
                                    <div class="word_box"
                                        :class="item.weekWords.length > 0 ? 'flex-align-between' : 'flex-align-around'">
                                        <template v-if="item.weekWords.length > 0">
                                            <div class="word_item flex-align-center"
                                                :class="{ 'word_item_active': defaultCellWordId == items.id }"
                                                v-for="(items, index) in item.weekWords" :key="index"
                                                @click="selectCourseItem(index, items.url, course_type, items.id, items.word)">
                                                {{ items.word }}</div>
                                        </template>
                                        <div class="word_empty" v-else>暂无内容</div>
                                    </div>
                                </div>
                            </div>
                            <!-- 硬笔体系课 -->
                            <div class="type_yingbi flex-column-between" v-if="course_type == 3">
                                <div class="yingbi_item flex-align-center" v-for="(item, index) in yingbiLesson"
                                    :key="index" :class="defaultCellWordId == item.id ? 'yingbi_item_active' : ''"
                                    @click="selectCourseItem(index, item.lesson, course_type, item.id, item.name)">
                                    <div class="flex-align">
                                        {{ item.name }}
                                        <img :src="defaultCellWordId == item.id ? require('../../assets/pen/yingbi_active.png') : require('../../assets/pen/yingbi.png')"
                                            alt="">
                                    </div>
                                </div>
                            </div>
                            <!-- 校本课程 -->
                            <div class="type_yingbi_school flex-column" v-if="course_type == 4 || course_type == 5">
                                <div class="search_box">
                                    <div class="search" v-if="course_type == 4">
                                        <el-select v-model="ListValue" placeholder="请选择" @change="semesterChange"
                                            :popper-append-to-body="false">
                                            <el-option v-for="(item, index) in semesterList" :key="index"
                                                :label="item.termName" :value="item.termName">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="search" v-if="course_type == 4">
                                        <el-select v-model="gradeValue" placeholder="请选择" @change="gradeChange"
                                            :popper-append-to-body="false">
                                            <el-option v-for="(item, index) in gradeList" :key="index" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="school_box flex-column" v-if="schoolCourseList.length > 0">
                                    <div class="yingbi_item flex-align-center" v-for="(item, index) in schoolCourseList"
                                        :key="index" :class="defaultSchoolWordId == index ? 'yingbi_item_active' : ''"
                                        @click="selectSchoolCourseItem(index, item)">
                                        <div class="flex-align">
                                            <span :class="defaultSchoolWordId == index ? 'yingbi_item_active' : ''">{{
                                                course_type == 4 ? item.courseName : item.name }}</span>
                                            <span :class="defaultSchoolWordId == index ? 'yingbi_item_active' : ''"
                                                style="margin-left: 10px;">{{ item.teacher }}</span>
                                            <img :src="defaultSchoolWordId == index ? require('../../assets/pen/yingbi_active.png') : require('../../assets/pen/yingbi.png')"
                                                alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="school_box flex-column-center" v-else>
                                    <img style="width: 300px;" src="../../assets/showreel/showreebg.png" />
                                    <span>暂无内容~</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom flex-column-center" :class="{ 'more_bottom': showMore }">
                    <div class="confirm" @click="ruanbiConfirm">确定</div>
                    <!-- <span @click="goHelper">我创建的</span> -->
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import {
    mapState,
    mapMutations
} from "vuex";
import schoolCourseDataBase from "../../views/schoolCourse.json"
export default {
    //import引入组件才能使用
    components: {
        slider,
        slideritem
    },
    props: ['UserInfoData', 'schoolId', 'CourseType_home'],
    data() {
        return {
            myTeachCourseVisible: false,
            defaultModelIndex: 0,
            modelList: [],
            currentIndex: 0,
            options: {
                currentPage: 0,
                tracking: false,
                thresholdDistance: 100,
                // thresholdTime: 300,
                preventDocumentMove: true,
                infinite: 4,
                slidesToScroll: 6,
                loop: false,
                preventRebound: false,
                freeze: true,
            },
            classList: [], // 单元测试、每日一字、软硬笔体系课班级列表
            defaultClass: null,//班级选中
            defaultTeacherId: null,//班级选择绑定的教师id
            defaultTermId: null,//班级选择绑定的学期id
            defaultGrade: null,//班级选择绑定的年级id
            defaultSchoolId: null,//班级选择绑定的学校id
            course_classList: [], // 单元测试、每日一字、软硬笔体系课班级列表
            defaultCourse: null,//课程选中
            course_type: 0, // 0-单元测试 (同步课程)\1-每日一字 \2-简洁版\3-硬笔体系课 \4-校本课程\5-我的课程
            cellDefaultIndex: 0,
            cellList: [], // 单元列表
            penList: ['1~4', '5~8', '9~12', '13~16'],
            cellWordList: [], // 单元下面的字列表
            defaultCellWordId: '', //单元测试、每日一字、软硬笔体系课默选中的字\课id
            dayWordList: [], //每日一字字列表
            yingbiLesson: [],
            gradeValue: 0,
            gradeList: [{
                value: 0,
                label: '全部年级'
            }, {
                value: 1,
                label: '1年级'
            }, {
                value: 2,
                label: '2年级'
            }, {
                value: 3,
                label: '3年级'
            }, {
                value: 4,
                label: '4年级'
            }, {
                value: 5,
                label: '5年级'
            }, {
                value: 6,
                label: '6年级'
            }],
            schoolCourseList: [],
            defaultSchoolWordId: 0, // 校本课程默认选中id
            showMore: false,
            course_level: null,
            courseLink: '',
            currentWordName: '',
            association_classLsit: [],
            defaultassociation: null,//关联课程选中
            associationIndex: 1,
            ListValue: null,
            semesterList: [],
            termYear: null,
            termNum: null
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {
        // schoolId(newValue) {
        //     if (newValue == '181096') {
        //         this.modelList = [{
        //             name: '同步课程',
        //             type: 0,
        //             show: this.CourseType_home.includes('11'),
        //         },
        //         // {
        //         //     name: '每日一字',
        //         //     type: 1,
        //         //     show: this.CourseType_home.includes('16')
        //         // },
        //         // {
        //         //     name: '简洁版',
        //         //     type: 2,
        //         //     show: this.CourseType_home.includes('12')
        //         // },
        //         {
        //             name: '硬笔体系课',
        //             type: 3,
        //             show: this.CourseType_home.includes('13')
        //         },
        //         {
        //             name: '校本课程',
        //             type: 4,
        //             show: this.CourseType_home.includes('14')
        //         },
        //         {
        //             name: '我的课程',
        //             type: 5,
        //             show: this.CourseType_home.includes('15')
        //         }
        //         ]
        //     } else {
        //         this.modelList = [{
        //             name: '同步课程',
        //             type: 0,
        //             show: this.CourseType_home.includes('11')
        //         },
        //         // {
        //         //     name: '每日一字',
        //         //     type: 1,
        //         //     show: this.CourseType_home.includes('16')
        //         // },
        //         //临时---
        //         // {
        //         //     name: '简洁版',
        //         //     type: 2,
        //         //     show: this.CourseType_home.includes('12')
        //         // },
        //         //临时---
        //         {
        //             name: '硬笔体系课',
        //             type: 3,
        //             show: this.CourseType_home.includes('13')
        //         },
        //         //临时----
        //         {
        //             name: '校本课程',
        //             type: 4,
        //             show: this.CourseType_home.includes('14')
        //         },
        //         {
        //             name: '我的课程',
        //             type: 5,
        //             show: this.CourseType_home.includes('15')
        //         }
        //             //临时---
        //         ]
        //     }
        // },
        UserInfoData(newValue) {
            this.modelList = [{
                name: '同步课程',
                type: 0,
                show: this.CourseType_home.includes('11')
            },
            // {
            //     name: '每日一字',
            //     type: 1,
            //     show: this.CourseType_home.includes('16')
            // },
            //临时 ----
            {
                name: '简洁版',
                type: 2,
                show: this.CourseType_home.includes('12')
            },
            //临时 ----
            {
                name: '硬笔体系课',
                type: 3,
                show: this.CourseType_home.includes('13')
            },

            {
                name: '校本课程',
                type: 4,
                show: this.CourseType_home.includes('14')
            },
            //临时 ----
            {
                name: '我的课程',
                type: 5,
                show: this.CourseType_home.includes('15')
            }
                //临时 ----
            ]
            this.modelList.filter(item => { return item.show })
            // console.log('this.modelList', this.modelList);

            this.getClassList(this.modelList[0].type);
            // if (newValue.id == 459894) {
            //     this.modelList = [{
            //         name: '同步课程',
            //         type: 0,
            //         show: this.CourseType_home.includes('11')
            //     },
            //     // {
            //     //     name: '每日一字',
            //     //     type: 1,
            //     //     show: this.CourseType_home.includes('16')
            //     // },
            //     //临时 ----
            //     // {
            //     //     name: '简洁版',
            //     //     type: 2,
            //     //     show: this.CourseType_home.includes('12')
            //     // },
            //     //临时 ----
            //     {
            //         name: '硬笔体系课',
            //         type: 3,
            //         show: this.CourseType_home.includes('13')
            //     },

            //     {
            //         name: '校本课程',
            //         type: 4,
            //         show: this.CourseType_home.includes('14')
            //     },
            //     //临时 ----
            //     {
            //         name: '我的课程',
            //         type: 5,
            //         show: this.CourseType_home.includes('15')
            //     }
            //         //临时 ----
            //     ]
            // }
        }
    },
    // 方法集合
    methods: {
        ...mapMutations(["attend_data", "hint_data", "message_data"]),
        // 打开闭弹窗
        openPendialog() {
            this.myTeachCourseVisible = true;

        },
        // 关闭弹窗
        closePendialog() {
            this.myTeachCourseVisible = false;
            this.showMore = false;
            this.defaultClass = this.classList[0].id;
            this.defaultModelIndex = 0;
            this.course_type = 0;
            this.cellDefaultIndex = 0;
            if (this.classList.length > 6) {
                this.$refs.slider1.$emit('slideTo', 0)
            }
            if (this.course_classList.length > 6) {
                this.$refs.slider2.$emit('slideTo', 0)
            }

        },
        // 选择课程类型(同步课、每日一字、硬笔体系课、校本课程)
        selectModel(index, type) {
            this.course_type = type;
            this.defaultModelIndex = index;
            this.cellDefaultIndex = 0;
            this.associationIndex = 1;
            this.cellWordList = [];
            this.dayWordList = [];
            this.yingbiLesson = [];
            this.schoolCourseList = [];
            if (type == 0) {
                // 单元测试
                // this.courseList = this.cellList;
                this.getCellWord(this.cellList[0].id);
            } else if (type == 2) {
                this.getConciseUnit(this.defaultGrade)
            } else if (type == 1) {
                // 每日一字
                this.getSysLessonList(this.defaultClass, 3)
                // this.getWordByDay(1)
            } else if (type == 3) {
                // 硬笔体系课
                this.getSysLessonList(this.defaultClass, 3)
            }
            // else if (type == 4 || type == 5) {
            //     this.defaultSchoolWeek = 1;
            //     this.getSchoolCourseList(this.defaultSchoolWeek, this.gradeValue);
            // } 
            else if (type == 4) {
                // this.getcourselib()
                // this.defaultSchoolWeek = 1;
                // this.getShareCourseware(type);
                // this.getClassList(type)
                this.getschoolcourselist_Class();
            } else if (type == 5) {
                this.getClassList(type)
                // this.getmycourse();
                // this.getcourselib()
                // this.defaultSchoolWeek = 1;
                // this.getShareCourseware(type);
            }
        },
        // 获取我教的课、软笔体系课班级列表
        getClassList(type) {
            // this.$Api.Home.getMyTeachClass()
            this.$Api.Home.getCourseInfo()
                .then(res => {
                    // console.log('res+++++++++++++', res);

                    this.classList = res.data.filter(item => {
                        return item.grade <= 12
                    });
                    this.defaultClass = res.data[0].id;
                    this.defaultTeacherId = res.data[0].teacherId;
                    this.defaultTermId = res.data[0].termId;
                    this.defaultGrade = res.data[0].grade;
                    this.defaultSchoolId = res.data[0].schoolId;
                    //默认请求 同步课程
                    if (type == 0) {
                        this.getCellList(res.data[0].grade)
                    } else if (type == 1) {

                    } else if (type == 2) {
                        this.getConciseUnit(res.data[0].grade)
                    } else if (type == 3) {
                        this.getSysLessonList(this.defaultClass, 3)
                    } else if (type == 4) {
                        //先获取课程
                        // this.getcourselib();
                        // this.getschoolcourselist_Class();
                    } else if (type == 5) {
                        this.getmycourse();
                    }

                    // if (this.defaultModelIndex == 2) {
                    //     this.getSysLessonList(this.defaultClass, 3)
                    // } else {
                    //     this.getCellList(res.data[0].grade)
                    // }
                    // this.getCellList(res.data[0].grade)
                    // 根据班级id获取单元列表

                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 根据年级id获取单元列表---同步课程
        getCellList(gradeId) {
            let data = {
                gradeId
            }
            this.$Api.Home.getMyTeachCell(data)
                .then(res => {
                    this.cellList = res.data;
                    if (res.data.length > 0) {
                        this.defaultUnitId = res.data[0].id;
                        this.getCellWord(res.data[0].id)
                    }

                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 根据年级id获取单元列表---同步课程--简洁版
        getConciseUnit(gradeId) {
            let data = {
                gradeId
            }
            this.$Api.Home.getConciseUnit(data)
                .then(res => {
                    this.cellList = res.data;
                    this.defaultUnitId = res.data[0].id;
                    this.getConciseWords(res.data[0].id)
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 选择班级
        selectClass(index, classId, gradeId, teacherId, termId, SchoolId) {
            this.penDefaultIndex = index;
            this.defaultClass = classId;
            this.defaultGrade = gradeId;
            this.defaultTeacherId = teacherId;
            this.defaultTermId = termId;
            this.defaultSchoolId = SchoolId;
            this.cellDefaultIndex = 0;
            this.cellWordList = [];
            this.dayWordList = [];
            this.yingbiLesson = [];
            // this.schoolCourseList = [];
            if (this.course_type == 0) {
                // 单元测试
                this.getCellList(gradeId);
            } else if (this.course_type == 2) {
                this.getConciseUnit(gradeId)
            } else if (this.course_type == 1) {
                // 每日一字
                this.getWordByDay(1, this.course_level)
            } else if (this.course_type == 3) {
                // 硬笔体系课
                this.getSysLessonList(this.defaultClass, 3)
            }
            // else if (this.course_type == 4 || this.course_type == 5) {
            //     // 旧校本课
            //     // this.getSysLessonList(this.defaultClass, 4)
            //     // this.getPenLesson(1);
            //     this.defaultSchoolWeek = 1;
            //     this.getSchoolCourseList(this.defaultSchoolWeek, this.gradeValue)
            // } 
            else if (this.course_type == 4) {
                // this.defaultSchoolWeek = 1;
                // this.associationIndex = 1;
                // this.defaultassociation = null;
                // this.association_classLsit = [];
                // this.getcourselib();

            } else if (this.course_type == 5) {
                this.associationIndex = 1;
                // this.getShareCourseware(this.course_type)
                this.getmycourse();
            }

        },
        //测试上下一页
        slideNextClass() {
            this.$refs.slider1.$emit("slideNext");
        },
        //测试上一页
        slidePreClass() {
            this.$refs.slider1.$emit("slidePre");
        },
        //测试上下一页
        slideNextCourse() {

            // console.log('下一页');
            this.$refs.slider2.$emit("slideNext");
        },
        //测试上一页
        slidePreClassCourse() {
            // console.log('上一页');
            this.$refs.slider2.$emit("slidePre");


        },

        //选择课程
        selectCourse_slider(index, brushLevelId, level) {
            this.defaultCourse = brushLevelId;
            this.course_level = level;
            this.cellDefaultIndex = 0;
            // 判断是每日一字还是硬笔体系课;
            if (this.course_type == 1) {
                this.getWordByDay(1, level)
            } else if (this.course_type == 3) {
                this.getPenLesson2(1, level);
            }
        },
        //选择课程
        association_slider(index, id) {
            this.defaultassociation = id;
            this.associationIndex = 1;
            // this.defaultCourse = brushLevelId;
            // this.course_level = level;
            // this.cellDefaultIndex = 0;
            // 判断是每日一字还是硬笔体系课;
            // if (this.course_type == 1) {
            //     this.getWordByDay(1, level)
            // } else if (this.course_type == 3) {
            //     this.getPenLesson2(1, level);
            // }
            this.getcourselibRel()
        },

        // 单元测试、每日一字、软硬笔体系课、校本课程选择课程/单元\周
        selectCourse(index, course_type) {
            this.yingbiLesson = [];
            this.dayWordList = [];
            this.schoolCourseList = [];
            this.defaultCellWordId = [];
            // this.courseItemDefaultIndex = 0;
            this.cellDefaultIndex = index;
            if (course_type == 0) {
                // 单元测试
                this.getCellWord(this.cellList[index].id)
            } else if (course_type == 2) {
                this.getConciseWords(this.cellList[index].id)
                // this.getConciseUnit(this.cellList[index].id)
            }
            else if (course_type == 1) {
                // 每日一字
                this.getWordByDay(index + 1, this.course_level)
            } else if (course_type == 3) {
                // 硬笔体系课
                this.getPenLesson2(index + 1, this.course_level)
            }
            // else if (course_type == 4 || course_type == 5) {
            //     // 校本课程
            //     this.defaultSchoolWeek = index + 1;
            //     this.getSchoolCourseList(this.defaultSchoolWeek, this.gradeValue)
            // }
            else if (course_type == 4) {
                // 校本课程
                this.associationIndex = index;
                // console.log('this.associationIndex', this.associationIndex);
                this.getschoolcourselist_Class();
                // this.getcourselib()
                // this.getcourselibRel(course_type)
                // this.getShareCourseware(course_type)
            } else if (course_type == 5) {

                this.associationIndex = index;
                // console.log('this.associationIndex', this.associationIndex);
                this.getmycourse()
            }

        },
        // 根据单元id获取单元测试课程
        getCellWord(unitId) {
            let data = {
                unitId
            }
            this.$Api.Home.getCellWord(data)
                .then(res => {
                    this.cellWordList = res.data;
                    this.courseLink = res.data[0].url;
                    this.defaultCellWordId = res.data[0].id;
                    this.currentWordName = res.data[0].name
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 根据单元id获取单元测试课程--简洁版
        getConciseWords(unitId) {
            let data = {
                unitId
            }
            this.$Api.Home.getConciseWords(data)
                .then(res => {
                    this.cellWordList = res.data;
                    this.courseLink = res.data[0].url;
                    this.defaultCellWordId = res.data[0].id;
                    this.currentWordName = res.data[0].name
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 单元测试、每日一字、软硬笔体系课选择对应字、课程
        selectCourseItem(index, lesson, type, wordId, name) {
            // this.courseItemDefaultIndex = index;
            this.courseLink = lesson;
            this.defaultCellWordId = wordId;
            this.currentWordName = name;
        },
        // 获取每日一字
        getWordByDay(index, level) {
            let weekList = {
                1: {
                    startWeek: 1,
                    endWeek: 4
                },
                2: {
                    startWeek: 5,
                    endWeek: 8
                },
                3: {
                    startWeek: 9,
                    endWeek: 12
                },
                4: {
                    startWeek: 13,
                    endWeek: 16
                },
            }
            let data = {
                startWeek: weekList[index].startWeek,
                endWeek: weekList[index].endWeek,
                // level: 2
                level
            }
            this.$Api.Home.getDayWord(data)
                .then(res => {
                    this.dayWordList = res.data;
                    this.courseLink = res.data[0].weekWords[0].url;
                    this.defaultCellWordId = res.data[0].weekWords[0].id;
                    this.currentWordName = res.data[0].weekWords[0].word
                })
                .catch(err => {
                    console.log('err:', err);
                    this.courseLink = '';
                    this.defaultCellWordId = '';
                    this.currentWordName = ''
                })
        },
        // 获取硬笔体系课
        getPenLesson2(page, level) {
            let data = {
                type: 3, // 3硬笔4软笔
                page, // 1(1~4课),2(5~8课),3(9~12课),4(13~16课)
                limit: 4,
                level: level
            }
            this.$Api.Home.getPenLesson(data)
                .then(res => {
                    this.yingbiLesson = res.data;
                    if (res.data.length > 0) {
                        this.courseLink = res.data[0].lesson;
                        this.defaultCellWordId = res.data[0].id;
                        this.currentWordName = res.data[0].name
                    } else {
                        this.courseLink = ''
                    }
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 校本课程年级筛选
        gradeChange(val) {
            // console.log('val', val);
            // this.getSchoolCourseList(this.defaultSchoolWeek, val)
            // this.getShareCourseware(this.course_type)
            this.getschoolcourselist_Class();
        },
        semesterChange(val) {
            // console.log('val学期', val);
            let obj = this.semesterList.find(item => item.termName == val);
            // console.log('obj', obj);
            this.termYear = obj.year;
            this.termNum = obj.term;
            this.getschoolcourselist_Class();
        },
        // 获取校本课程列表
        getSchoolCourseList(week, grade) {
            // 先查周，在根据周查年级 schoolCourseDataBase
            let list = schoolCourseDataBase[week];
            let list2 = [];
            list.map(item => {
                if (item.grade.indexOf(grade) != -1) {
                    list2.push(item)
                }
            });
            this.schoolCourseList = list2;
            this.defaultSchoolWordId = 0;
            if (this.schoolCourseList.length > 0) {
                this.courseLink = this.schoolCourseList[0].url;
                this.currentWordName = this.schoolCourseList[0].name;
            }

        },
        // 获取校本课程5和我的课程6
        async getShareCourseware(type) {
            // console.log('type', type);

            let data;
            if (type == 4) {
                data = {
                    courseType: 5,
                    weekNum: this.cellDefaultIndex + 1,
                    termId: this.defaultTermId,
                    grade: this.gradeValue == 0 ? '' : this.gradeValue,
                    schoolId: this.defaultSchoolId,
                };
            } else {
                data = {
                    courseType: 6,
                    classId: this.defaultClass,
                    weekNum: this.cellDefaultIndex + 1,
                    teaId: this.defaultTeacherId,
                    termId: this.defaultTermId,
                    grade: this.gradeValue == 0 ? '' : this.gradeValue,
                    schoolId: this.defaultSchoolId,
                };
            }

            let resdata = await this.$Api.Home.getShareCourseware(data);
            this.schoolCourseList = resdata.data;
            this.defaultSchoolWordId = 0;
            if (this.schoolCourseList.length > 0) {
                this.courseLink = this.schoolCourseList[0].lesson;
                this.currentWordName = this.schoolCourseList[0].name;
            }
        },
        // 课程列表
        getSysLessonList(id, type) {
            let data = {
                classId: id,
                type: type
            }
            this.$Api.Home.getSysLessonList(data)
                .then(res => {
                    this.course_classList = res.data;
                    this.defaultCourse = res.data[0].brushLevelId;
                    this.course_level = res.data[0].level;
                    if (this.course_type == 1) {
                        this.getWordByDay(1, this.course_level);
                    } else if (this.course_type == 3) {
                        this.getPenLesson2(1, this.course_level);
                    }
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 校本课程选择课程
        selectSchoolCourseItem(index, item) {
            if (this.course_type == 4) {
                this.courseLink = item.lesson;
                this.currentWordName = item.courseName;
                this.defaultSchoolWordId = index;

            }
            if (this.course_type == 5) {
                this.courseLink = item.lesson;
                this.currentWordName = item.name;
                this.defaultSchoolWordId = index;

            }
        },
        // 确定跳转腾讯
        ruanbiConfirm() {
            if (!this.courseLink) {
                this.hint_data("暂未开放");
                this.$emit("popupVisible");
            } else {
                // 添加上课记录
                let type = {
                    0: '同步课程',
                    1: '每日一字',
                    2: '简洁版',
                    3: '硬笔体系课',
                    4: '校本课程',
                    5: '校本课程',
                }
                let data = {
                    classId: this.defaultClass,
                    userId: this.UserInfoData.id,
                    roleId: this.UserInfoData.role,
                    title: this.currentWordName,
                    content: '',
                    type: 1,
                    action: type[this.course_type],
                    schoolId: this.schoolId
                }
                this.$Api.Home.addLog(data)
                    .then(res => {
                        this.$confirm('课程已打开', '提示', {
                            confirmButtonText: '确定',
                            showCancelButton: false,
                            type: 'success',
                            center: true
                        })
                        let url = new URL(this.courseLink);
                        let searchParams = new URLSearchParams(url.search);
                        searchParams.set('wordId', res.data);
                        searchParams.delete('hash');
                        searchParams.set('hash', url.hash.slice(1));
                        url.search = searchParams.toString();
                        window.open(url.href)
                    })
                    .catch(err => {
                        console.log('添加上课记录失败:', err)
                    })
            }
        },
        // 我创建的 跳转教师助手
        goHelper() {
            let data = {
                url: '#/course/created-quda'
            }
            // window.open("https://teacher.qq.com/#/course/created-quda");
            this.$Api.Home.getDirectUrl2(data)
                .then(res => {
                    window.open(res.data);
                })
                .catch(err => {
                    console.log(err)
                })
        },
        //关联课程课程
        getcourselib() {
            let data = {
                schoolId: this.defaultSchoolId,
                termId: this.defaultTermId,
            }
            this.$Api.Home.getcourselib(data)
                .then(res => {
                    // console.log('res关联课程课程', res);
                    this.association_classLsit = res.data;
                    this.defaultassociation = res.data[0].id;
                    this.getcourselibRel();
                })
                .catch(err => {
                    // console.log('添加上课记录失败:', err)
                })
        },
        //校本课
        getcourselibRel() {
            let data = {
                courseLibId: this.defaultassociation,
                // weekNum:this.cellDefaultIndex,
                weekNum: this.associationIndex,
            }
            this.$Api.Home.getcourselibRel(data)
                .then(res => {
                    // console.log('res校本课', res);
                    this.schoolCourseList = res.data;
                    this.defaultSchoolWordId = 0;
                    if (this.schoolCourseList.length > 0) {
                        this.courseLink = this.schoolCourseList[0].lesson;
                        this.currentWordName = this.schoolCourseList[0].courseName;
                    }

                })
                .catch(err => {
                    // console.log('添加上课记录失败:', err)
                })
        },
        //
        getschoolcourselist_Class() {
            // console.log('11111111111111', this.termYear);

            let data = {
                schoolId: this.defaultSchoolId,
                grade: this.gradeValue == 0 ? '' : this.gradeValue,
                weekNum: this.associationIndex,
                termYear: this.termYear,
                termNum: this.termNum,
            }
            // console.log('11111111111111', data);
            this.$Api.Home.getschoolcourselist(data)
                .then(res => {
                    // console.log('res校本课', res);

                    this.schoolCourseList = res.data;
                    this.defaultSchoolWordId = 0;
                    if (this.schoolCourseList.length > 0) {
                        this.courseLink = this.schoolCourseList[0].lesson;
                        this.currentWordName = this.schoolCourseList[0].courseName;
                    }
                })
                .catch(err => {
                    // console.log('添加上课记录失败:', err)
                })
        },
        getmycourse() {
            let data = {
                courseType: 6,
                classId: this.defaultClass,
                weekNum: this.associationIndex,
                teaId: window.sessionStorage.getItem('teacherId'),
                // grade: this.defaultClass,
                // weekNum: this.associationIndex,
                // weekNum:this.cellDefaultIndex,
                // teaId: window.sessionStorage.getItem('teacherId'),
            }
            // this.$Api.Home.getmycourse(data)
            this.$Api.Home.getShareCourseware(data)
                .then(res => {
                    // console.log('res我的课程', res);
                    this.schoolCourseList = res.data;
                    this.defaultSchoolWordId = 0;
                    if (this.schoolCourseList.length > 0) {
                        this.courseLink = this.schoolCourseList[0].lesson;
                        this.currentWordName = this.schoolCourseList[0].name;
                    }

                })
                .catch(err => {
                    // console.log('添加上课记录失败:', err)
                })
        },
        getqueryTermList() {
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    // console.log('我的学期', res);
                    this.semesterList = res.data;
                    this.ListValue = res.data[0].termName;
                    this.termYear = res.data[0].year;
                    this.termNum = res.data[0].term;
                    // console.log('this.termYear',this.termYear);

                })
                .catch(err => {
                    // console.log('添加上课记录失败:', err)
                })
        }

    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.getqueryTermList();
    },
}
</script>
<style scoped lang="less">
@import "./less/integratedCurriculum.less";
</style>