<template>
    <div>
        <el-dialog :visible="penDialogVisible" custom-class="pendialog flex-column" :show-close="false"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="penTitle flex-align-center">软笔课程</div>
            <div class="penBox">
                <img src="../../assets/pen/close.png" class="close" @click="closePendialog">
                <div class="classBox flex-layout">
                    <span class="selectClass flex-align-center">选择班级:</span>
                    <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-left" v-if="ruanbiClassList.length > 4"
                            @click="slidePrecalligraphy" @touchstart.prevent="slidePrecalligraphy"></i></div>
                    <div class="classList_slide">
                        <slider ref="slider3" :options="options" is-prevent="true">
                            <slideritem v-for="(item, index) in ruanbiClassList" :key="index">
                                <div class="classList_slide_class classList_slide_class_p" 
                                    :class="defaultClass == item.id ? 'classItem_active' : ''"
                                    @click="selectClass(index, item.id, item.grade)" @touchstart.prevent="selectClass(index, item.id, item.grade)">{{ item.name }}</div>
                            </slideritem>
                        </slider>
                    </div>
                    <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-right"
                            v-if="ruanbiClassList.length > 4" @click="slideNextcalligraphy" @touchstart.prevent="slideNextcalligraphy"></i></div>
                </div>
                <div class="classBox flex-layout">
                    <span class="selectClass flex-align-center">课程:</span>
                    <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-left"
                            v-if="course_classList.length > 4" @click="slidePrecalligraphy_c" @touchstart.prevent="slidePrecalligraphy_c"></i></div>
                    <div class="classList_slide">
                        <slider ref="slider4" :options="options" is-prevent="true">
                            <slideritem v-for="(item, index) in course_classList" :key="index">
                                <div class="classList_slide_class"
                                    :class="defaultCourse == item.brushLevelId ? 'classItem_active' : ''"
                                    @click="selectCourse_slider(index, item.brushLevelId, item.level)" @touchstart.prevent="selectCourse_slider(index, item.brushLevelId, item.level)">{{ item.brushName }}
                                </div>
                            </slideritem>
                        </slider>
                    </div>
                    <div class="arrow_cut flex-align-center"><i class="el-icon-arrow-right"
                            v-if="course_classList.length > 4" @click="slideNextcalligraphy_c" @touchstart.prevent="slideNextcalligraphy_c"></i></div>
                </div>
                <div class="courseWrap flex-column-center">
                    <div class="courseInfo flex-align-between">
                        <div class="left flex-layout">
                            <div class="title">选课:</div>
                            <div class="courseList flex-column">
                                <div class="courseItem flex-align-center"
                                    :class="courseDefaultIndex == index ? 'courseItem_active' : ''"
                                    v-for="(item, index) in penList" :key="index" @click="selectCourse(index, course_type)">
                                    {{ item }}
                                </div>
                                <img class="maobi" src="../../assets/pen/maobi.png" alt="">
                            </div>
                        </div>
                        <div class="right flex-column">
                            <div class="course_item flex-align-around"
                                :class="defaultCellWordId == item.id ? 'course_item_active' : ''"
                                v-for="(item, index) in ruanbiLesson" :key="index"
                                @click="selectCourseItem(index, item.lesson, course_type, item.id, item.name)">
                                <div class="flex-align">
                                    <span>{{ item.name }}</span>
                                    <img :src="courseItemDefaultIndex == index ? require('../../assets/pen/pen_active.png') : require('../../assets/pen/pen.png')"
                                        alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom flex-column-center">
                        <div class="confirm" @click="ruanbiConfirm">确定</div>
                        <span @click="goHelper">我创建的</span>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import {
    mapState,
    mapMutations
} from "vuex";
export default {
    //import引入组件才能使用
    components: {
        slider,
        slideritem
    },
    props: ['UserInfoData', 'schoolId'],
    data() {
        return {
            //关闭弹窗
            penDialogVisible: false,
            //滑动组件
            options: {
                currentPage: 0,
                tracking: false,
                thresholdDistance: 100,
                // thresholdTime: 300,
                preventDocumentMove: true,
                infinite: 4,
                slidesToScroll: 4,
                loop: false,
                preventRebound: false,
                freeze: true,
                pagination: false
            },
            course_classList: [], // 单元测试、每日一字、软硬笔体系课班级列表
            defaultClass: null,
            defaultCourse: null,
            courseDefaultIndex: 0,
            penList: ['1~4', '5~8', '9~12', '13~16'],
            course_type: 0, // 0-单元测试 1-每日一字 2-硬笔体系课 3-软笔体系课
            course_level: null,
            defaultCellWordId: '', //单元测试、每日一字、软硬笔体系课默选中的字\课id
            ruanbiLesson: [],
            courseItemDefaultIndex: 0,
            ruanbiClassList: [],
            courseLink: '', // 单元测试、每日一字、软硬笔体系课跳转链接
            currentWordName: ''
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        ...mapMutations(["attend_data", "hint_data", "message_data"]),
        openPendialog() {
            this.penDialogVisible = true;
            this.getClassList();
        },
        //关闭弹窗
        closePendialog() {
            this.penDialogVisible = false;
            this.defaultClass = null;
            this.defaultCourse = null;
            this.courseDefaultIndex = 0;
            this.$refs.slider3.$emit('slideTo', 0)
            this.$refs.slider4.$emit('slideTo', 0)
        },
        //测试上下一页
        slideNextcalligraphy() {
            this.$refs.slider3.$emit("slideNext");
        },
        //测试上一页
        slidePrecalligraphy() {
            this.$refs.slider3.$emit("slidePre");
        },
        //测试上下一页
        slideNextcalligraphy_c() {
            this.$refs.slider4.$emit("slideNext");
        },
        //测试上一页
        slidePrecalligraphy_c() {
            this.$refs.slider4.$emit("slidePre");
        },
        // 获取我教的课、软笔体系课班级列表
        getSysLessonList(id, type) {
            let data = {
                // classId: 30346,
                // type: 3
                classId: id,
                type: type
            }
            let that = this;
            that.defaultCourse = null;
            this.$Api.Home.getSysLessonList(data)
                .then(res => {
                    that.course_classList = res.data;
                    that.defaultCourse = res.data[0].brushLevelId;
                    that.course_level = res.data[0].level;
                    that.getPenLesson(1, that.course_level)
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 单元测试、每日一字、软硬笔体系课、校本课程选择课程/单元\周
        selectCourse(index, course_type) {
            this.courseDefaultIndex = index;
            this.getPenLesson(index + 1, this.course_level);
        },
        // 单元测试、每日一字、软硬笔体系课选择对应字、课程
        selectCourseItem(index, lesson, type, wordId, name) {
            this.courseItemDefaultIndex = index;
            this.courseLink = lesson;
            this.defaultCellWordId = wordId;
            this.currentWordName = name
        },
        // 确定跳转腾讯
        ruanbiConfirm() {
            if (!this.courseLink) {
                this.hint_data("暂未开放");
                this.$emit("popupVisible");
            } else {
                let data = {
                    classId: this.defaultClass,
                    userId: this.UserInfoData.id,
                    roleId: this.UserInfoData.role,
                    title: this.currentWordName,
                    content: '',
                    type: 1,
                    action: '软笔体系课',
                    schoolId: this.schoolId
                }
                console.log('data', data);
                console.log('this.courseLink', this.courseLink);
                this.$Api.Home.addLog(data)
                    .then(res => {
                        this.$confirm('课程已打开', '提示', {
                            confirmButtonText: '确定',
                            showCancelButton: false,
                            type: 'success',
                            center: true
                        })
                        window.open(this.courseLink)
                    })
                    .catch(err => {
                        console.log('添加上课记录失败:', err)
                    })
            }

            // }
        },
        // 我创建的 跳转教师助手
        goHelper() {
            let data = {
                url: '#/course/created-quda'
            }
            // window.open("https://teacher.qq.com/#/course/created-quda");
            this.$Api.Home.getDirectUrl2(data)
                .then(res => {
                    console.log('res', res);
                    // window.open(res.data);
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 选择班级
        selectClass(index, classId, gradeId) {
            //高亮位置
            this.defaultClass = classId;
            //请求课程类别
            this.getSysLessonList(this.defaultClass, 4);
            //筛选后初始化 选课（周列）
            this.courseDefaultIndex = 0;

        },
        //选择课程
        selectCourse_slider(index, brushLevelId, level) {
            this.defaultCourse = brushLevelId;
            this.courseDefaultIndex = 0;
            this.course_level = level;
            this.getPenLesson(1, level);
        },
        // 获取软笔体系课
        getPenLesson(page, level) {
            let data = {
                type: 4, // 3硬笔4软笔
                page, // 1(1~4课),2(5~8课),3(9~12课),4(13~16课)
                limit: 4,
                level: level
            }
            this.$Api.Home.getPenLesson(data)
                .then(res => {
                    this.ruanbiLesson = res.data;
                    if (res.data.length > 0) {
                        this.courseLink = res.data[0].lesson;
                        this.defaultCellWordId = res.data[0].id;
                        this.currentWordName = res.data[0].name
                    } else {
                        this.courseLink = ''
                    }
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 获取软笔体系课班级列表
        getClassList() {
            let that = this;
            this.$Api.Home.getMyTeachClass()
                .then(res => {
                    that.ruanbiClassList = res.data.filter(item => {
                        return item.termId >= 5 || item.grade >= 5 && item.grade <= 12;
                    });
                    that.selectClass(0, that.ruanbiClassList[0].id, that.ruanbiClassList[0].grade)
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        // 
    },
}
</script>
<style scoped lang="less">
@import "./less/calligraphyPop.less";
</style>